.toast-error-container {
  border-radius: 16px !important;
  border: 1px solid var(--Base-Red) !important;
}

.toast-success-container {
  position: relative;
  z-index: 3;
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 16px;
    padding: 1.1px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.02),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.25)
    );
    -webkit-mask: linear-gradient(var(--White) 0 0) content-box,
      linear-gradient(var(--White) 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
